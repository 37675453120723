







import { Component, Prop, Vue } from "vue-property-decorator";
import { Document } from "@/types";
import {
  mdiFileImageOutline,
  mdiFileOutline,
  mdiFilePdfOutline
} from "@mdi/js";
@Component
export default class LabelDocument extends Vue {
  @Prop() readonly doc!: Document;
  @Prop({ default: 15 }) readonly truncateLength!: number;
  public name(name: string) {
    const nameLength = name.length;
    if (nameLength <= this.truncateLength) return name;
    const charsToShow = this.truncateLength - 3;
    return (
      name.substr(0, Math.ceil(charsToShow / 2)) +
      "..." +
      name.substr(nameLength - Math.floor(charsToShow / 2))
    );
  }
  private disabled = false;
  onRemove() {
    this.disabled = true;
    this.$emit("remove");
  }
  public icon(): string {
    switch (this.doc.type) {
      case "pdf":
        return mdiFilePdfOutline;
      case "image":
        return mdiFileImageOutline;
      default:
        return mdiFileOutline;
    }
  }
}
